import React, { Component } from 'react';
import { Carousel } from 'intdev-ui';

export class EntryAttachmentCarousel extends Component {
    state = {
        images: [],
        startIndex: 0,
        open: false,
    };

    componentDidMount() {
        window.addEventListener('openEntryAttachmentCarousel', this.handleOpenCarousel);
    }

    componentWillUnmount() {
        window.removeEventListener('openEntryAttachmentCarousel', this.handleOpenCarousel);
    }

    handleOpenCarousel = (event) => {
        this.setState({ ...event.detail, open: true });
    };

    handleCloseCarousel = () => {
        this.setState({
            images: [],
            startIndex: 0,
            open: false,
        });
    };

    render() {
        const { images, startIndex, open } = this.state;
        return (
            <Carousel
                onRequestClose={ this.handleCloseCarousel }
                open={ open }
                images={ images }
                startIndex={ startIndex }
            />
        );
    }
}
