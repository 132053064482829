import React from 'react';
import { render } from '../common/helpers/render';
import { EntryAttachmentCarousel } from './EntryAttachmentCarousel';

window.addEventListener('DOMContentLoaded', () => {
    const carouselContainer = document.getElementById('attachment-carousel-container');
    if (carouselContainer) {
        render(
            <EntryAttachmentCarousel />,
            carouselContainer,
        );
    }
});
